import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import DB from './DB.js'

const url = location.href;
const words = url.split('/');

DB.load(function(){
    createApp(App).use(router).mount('#app')
},
// URLを/（スラッシュ）で分割してwords配列の3番に本の文字列がある（kiso/rinsho）。
words[3]
)
