<!-- 
    TOP001_top.vue
    トップ画面。
    「一問一答」か「確認テスト」かを選択する。
 -->
<template>
  <main class="l-main l-top">
    <div class="l-inner">
      <div class="p-top">
        <div class="p-top__head">
          <div class="p-top__img-area">
            <h1>
              <img src="/assets/img/mv.svg" alt="" class="p-top__img">
            </h1>
          </div>
        </div>
        <div class="p-top__body">
          <div class="p-top__heading">
            <h2 class="c-heading__big">
              <div v-html="bookName"></div>
            </h2>
          </div>

          <div class="p-btn__wrapper-vertical p-top__btns">
            <button class="c-btn__icon c-btn__icon--correct" type="button" @click="goNext(0)">
              <span class="c-btn__icon-text">
                一問一答問題でチェック
              </span>
            </button>
            <button class="c-btn__icon c-btn__icon--flag" type="button" @click="goNext(1)">
              <span class="c-btn__icon-text">
                分野別確認テスト
              </span>
            </button>
          </div>

          <div class="p-top__text-area">
            <p class="p-top__text">
              「一問一答問題でチェック」の正答率が8割を超えてきたら分野別確認テストにチャレンジしよう！
            </p>
          </div>
          <!-- 2024.2.16 文言追加 -->
          <div class="p-top__attention">
            <div class="c-attention">
              <p class="c-attention__text">
                本webシステムは 「{{ removeSpaceAndNewLine(bookName) }}」の購入者向け付録であり、著作権は南江堂に帰属します。
                本 web システムの一部または全部を、無断で複製、改変、頒布（無料での配布および有料での販売）することを禁止します。
              </p>
            </div>
          </div>
          <!-- /2024.2.16 文言追加 -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// DB.jsで読み込んだデータを取得
import DB from '../DB.js'
export default {
  data() {
    return DB;
  },
  methods: {
    // 引数0：一問一答へ、引数1：確認テストへ
    goNext: function (question_format) {
      this.question_format = question_format;
      let nextPageName = '';
      if (question_format == 0) {
        nextPageName = 'SEL001_material-practice';
      } else {
        nextPageName = 'CHA001_material-test';
      }
      this.saveBackup();
      this.$router.push({ name: nextPageName, params: { book: this.book } })
    },
  },
};
</script>
