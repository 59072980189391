/* global $ */
const DB = {
    // questions
    Q_CHAPTER:0,
    Q_COMMENT:1,
    Q_SECTION:2,
    Q_TERM:3,
    Q_QUESTION_DISCRIPTION:4,
    Q_QUESTION:5,
    Q_REFERENCE:6,
    Q_IMAGE:7,
    Q_EXAMINATION:8,
    Q_ANSWER:9,

    // examinations
    E_PART:0,
    E_PAGES:1,
    E_TERM:2,
    E_EXAMINATION:3,

    bookName: "",
    questions: [],
    examination: [],
    shuffledQuestions: [],
    // 50問に制限するか制限せずに全ての問題を解くか。0の場合は制限なしで全ての問題を解くとする。1以上の数字はその数をユーザが解く問題数とする。
    limit: 0,
    // ユーザの解答を一時的に保持するプロパティ
    currentUserAnswer: '○',
    // 問題形式	一問一答なのか確認テストなのか
    question_format: 0,
    // 設問カテゴリ	解答中の設問カテゴリを保持。解答画面のヘッダーにタイトルを表示するのに使う。
    question_category: "",
    // 章	今解いている問題の章。一つの設問カテゴリを一通り解いて、教材選択画面に戻ってきた時に、さっき解いていた章を再び表示するために使う
    chapter_number: 1,
    // 確認テスト名  今解いている確認テストの名前
    examination_name: "",
    // 解答数	解答した数
    number_of_answer: 0,
    // 正答数	正解した数
    number_of_correct: 0,
    // URLに含まれる本を識別する文字列（kiso, rinsho)
    book: "",
    // 章節項の情報を階層を作って保持する
    questionsTree: [],
    init: function () {
        DB.number_of_answer = 0;
        DB.number_of_correct = 0;
    },

    load: function (callback, book) {
        DB.book = book;
        $.get("../DATA/" + book + ".tsv", function (res) {
            // 改行コードで分割
            DB.examination = res.split('\n');

            // ダブルクオートで次の行と連結
            for (let i = 0; i < DB.examination.length; i++) {
                if (DB.examination[i].match('"')) {
                    DB.examination[i] = DB.examination[i] + DB.examination[i + 1];
                    DB.examination[i] = DB.examination[i].replace('"', '');
                    DB.examination[i] = DB.examination[i].replace('"', '');
                    DB.examination.splice(i + 1, 1);
                    i--;
                }
            }

            // tabで分割
            DB.examination = DB.examination.map(line => line.split("\t"));

            // 本の正式名称を取り出す。
            DB.bookName = DB.examination[1][1];
            // 空行、カラム行が合わせて4行分存在する前提で、これを削除。
            for (let i = 0; i < 4; i++) {
                DB.examination.shift();
            }

            // 問題文のデータと確認テストのデータを分割。空行で判別。分割したら空行は削除。
            while (!(
                DB.examination[0][DB.Q_CHAPTER] == '' &&
                DB.examination[0][DB.Q_SECTION] == '' &&
                DB.examination[0][DB.Q_TERM] == '' &&
                DB.examination[0][DB.Q_QUESTION_DISCRIPTION] == '' &&
                DB.examination[0][DB.Q_QUESTION] == '' &&
                DB.examination[0][DB.Q_REFERENCE] == '' &&
                DB.examination[0][DB.Q_IMAGE] == '' &&
                DB.examination[0][DB.Q_EXAMINATION] == ''
            )) {
                DB.questions.push(DB.examination.shift());
            }
            DB.examination.shift();

            // 何も入っていないカラムを削除する。
            let examinationLength = 0;
            for (let i of DB.examination[0]) {
                if (i != '') {
                    examinationLength++;
                }
            }
            DB.examination.shift();
            for (let i = 0; i < DB.examination.length; i++) {
                let currentlength = DB.examination[i].length;
                for (let j = 0; j < currentlength - examinationLength; j++) {
                    DB.examination[i].pop();
                }

            }

            //空のレコード（行）を削除する
            DB.examination = DB.examination.filter((v)=>{
                return v.join('').trim() != "";
            })

            // 答え（○×）を別の配列の要素として取り出す。かっこは消す。
            DB.questions.forEach(element => {
                //まるの記号は「記号」と「漢数字」の両方を見る
                if (element[DB.Q_QUESTION].match('（○）') || element[DB.Q_QUESTION].match('（◯）')) {
                    element[DB.Q_ANSWER] = '○';
                    // スペース一つ、かっこ、○記号の4文字を削除
                    element[DB.Q_QUESTION] = element[DB.Q_QUESTION].slice(0, -4);
                } else if (element[DB.Q_QUESTION].match('（×）')) {
                    element[DB.Q_ANSWER] = '×';
                    // スペース一つ、かっこ、×記号の4文字を削除
                    element[DB.Q_QUESTION] = element[DB.Q_QUESTION].slice(0, -4);
                }
            })

            DB.questions.forEach(element => {
                let sectionsArray = [];
                let termsArray = [];
                if (element[DB.Q_CHAPTER] != '') {
                    DB.questionsTree.push(new node(element[DB.Q_CHAPTER]));
                } else {
                    if (element[DB.Q_SECTION] != '') {
                        sectionsArray = DB.questionsTree[DB.questionsTree.length - 1].array;
                        sectionsArray.push(new node(element[DB.Q_SECTION],element[DB.Q_COMMENT]));
                        sectionsArray[sectionsArray.length - 1].content = 'close';
                        termsArray = sectionsArray[sectionsArray.length - 1].array;
                        termsArray.push(new node(element[DB.Q_TERM]));
                        termsArray[termsArray.length - 1].content = element[DB.Q_QUESTION_DISCRIPTION];
                    } else {
                        if (element[DB.Q_TERM] != '') {
                            sectionsArray = DB.questionsTree[DB.questionsTree.length - 1].array;
                            termsArray = sectionsArray[sectionsArray.length - 1].array;
                            termsArray.push(new node(element[DB.Q_TERM]));
                            termsArray[termsArray.length - 1].content = element[DB.Q_QUESTION_DISCRIPTION];
                        }
                    }
                }
            });

            // 章のタイトル、節のタイトル、項のタイトル、問題文説明の4項目を各問題文の要素にコピーする。
            fillArray(DB.examination, DB.E_EXAMINATION);
            fillArray(DB.questions, DB.Q_QUESTION);

            // 確認テストの「パーツ」の文字列を取り除いて数字のみにする。
            for (let i = 0; i < DB.examination.length; i++) {
                DB.examination[i][DB.E_PART] = DB.examination[i][DB.E_PART].slice(3);
            }
            callback();
        })
    },

    // 選択された一問一答の設問カテゴリを抽出し、配列をシャッフルするメソッド
    shuffleQestions: function () {
        DB.shuffledQuestions = [];
        let tmp1 = extractAndDeepCopy(DB.questions);
        DB.shuffledQuestions = shuffleArray(tmp1);
    },

    // 章名の配列を取得するメソッド
    getChapters: function () {
        return getOneColumn(DB.questions, DB.Q_CHAPTER);
    },

    // 節名の配列を取得するメソッド
    getSections: function () {
        return getOneColumn(DB.questions, DB.Q_SECTION);
    },

    // 項名の配列を取得するメソッド
    getTerms: function () {
        let ans = [];
        let tmp = [];
        for (let element1 of DB.questions) {
            if (tmp != element1[DB.Q_TERM] && element1[DB.Q_CHAPTER].slice(1, 2) == this.chapter_number) {
                tmp = element1[DB.Q_TERM];
                ans.push(tmp);
            }
        }
        return ans;
    },

    // 問題説明文の配列を取得するメソッド
    getQuestionDiscription: function () {
        return getOneColumn(DB.questions, DB.Q_QUESTION_DISCRIPTION);
    },

    getExaminationAccordion: function () {
        let accordion = [
            [DB.examination[0][DB.E_PART], DB.examination[0][DB.E_PAGES], DB.examination[0][DB.E_TERM]]
        ];

        for (let element1 of DB.examination) {
            if (element1[DB.E_PART] != accordion[accordion.length - 1][DB.E_PART]) {
                let array = [];
                for (let i = 0; i < element1.length - 1; i++) {
                    array.push(element1[i]);
                }
                accordion.push(array);
            }
        }
        return accordion;
    },

    extractExamination: function () {
        let array = [];
        for (let element1 of DB.questions) {
            if (element1[DB.Q_EXAMINATION] == DB.examination_name) {
                array.push(element1);
            }
        }
        DB.shuffledQuestions = shuffleArray(array);
        // DB.shuffledQuestions = array;
        DB.limit = DB.shuffledQuestions.length;
    },

    saveBackup: function () {
        const jsonDb = JSON.stringify(DB);
        sessionStorage.setItem("DB", jsonDb);
    },

    loadBackup: function () {
        return JSON.parse(sessionStorage.getItem("DB"));
    },

    removeSpaceAndNewLine: function (str) {
        let result = str.replace(/\s/g, "");
        result = result.replace("<br>", "");
        return result;
    }
};

//配列の空白を埋める関数。配列の主要素（問題文やテスト名）の列番号を指定する。
const fillArray = function (array, key) {
    for (let i = 0; i < array.length; i++) {
        if (array[i][key] == '') {
            for (let j = key - 1; j >= 0; j--) {
                if (array[i][j] != '') {
                    let tmp = array[i][j];
                    for (let k = i + 1; k < array.length; k++) {
                        if (array[k][j] != '') {
                            break;
                        }
                        array[k][j] = tmp;
                    }
                }
            }
            array.splice(i, 1);
            i--;
        }
    }
}

const getOneColumn = function (array, column) {
    let ans = [];
    let tmp = array[0][column];
    ans.push(tmp);
    for (let element1 of array) {
        if (tmp != element1[column]) {
            tmp = element1[column];
            ans.push(tmp);
        }
    }
    return ans;

}

// 配列をシャッフルするメソット。引数のarrayは参照渡しなので消えても大丈夫な配列を渡す。
const shuffleArray = function (array) {
    let newArray = [];
    while (array.length > 0) {
        let n = array.length;
        let k = Math.floor(Math.random() * n);

        newArray.push(array[k]);
        array.splice(k, 1);
    }
    return newArray;
}

// 配列をdeep copyし、設問カテゴリから問題の抽出を同時に行うメソッド
const extractAndDeepCopy = function (array) {
    let tmp1 = [];
    for (let element1 of array) {
        if (element1[DB.Q_TERM] == DB.question_category) {
            let tmp2 = [];
            for (let element2 of element1) {
                tmp2.push(element2);
            }
            tmp1.push(tmp2);
        }
    }
    return tmp1;
}

export default DB

// questionsTreeに格納するクラス。文字列と配列を持つ。
class node {
    name;
    comment;
    content;
    array = [];
    constructor(name,comment) {
        this.name = name;
        this.comment = comment;
    }
}