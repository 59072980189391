import { createRouter, createWebHistory } from 'vue-router'

// コンポーネントをインポート
import TOP001 from '../components/TOP001_top.vue';
import SEL001 from '../components/SEL001_material-practice.vue';
import SEL002 from '../components/SEL002_limit.vue';
import CHA001 from '../components/CHA001_material-test.vue';
import ANS001 from '../components/ANS001_question.vue';
import ANS003 from '../components/ANS003_result.vue';
import ERR001 from '../components/ERR001_error.vue';

const routes = [
  {
    path: '/:book',
    name: 'book_root',
    redirect: { name: 'TOP001_top' },
  },
  {
    path: '/:book/',
    name: 'book_root',
    redirect: { name: 'TOP001_top' },
  },
  {
    path: '/:book/top',
    name: 'TOP001_top',
    component: TOP001,
  },
  {
    path: '/:book/material-practice',
    name: 'SEL001_material-practice',
    component: SEL001
  },
  {
    path: '/:book/limit',
    name: 'SEL002_limit',
    component: SEL002
  },
  {
    path: '/:book/material-test',
    name: 'CHA001_material-test',
    component: CHA001
  },
  {
    path: '/:book/question',
    name: 'ANS001_question',
    component: ANS001,
  },
  {
    path: '/:book/result',
    name: 'ANS003_result',
    component: ANS003
  },
  {
    path: '/:book/error',
    name: 'ERR001_error',
    component: ERR001
  },
  {
    path: '/:book/:catchAll(.*)',
    name: 'others',
    redirect: { name: 'ERR001_error' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router